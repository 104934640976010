import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {format, isValid} from "date-fns";
import {StockStatusMap} from "../../../utils/dataStore/mapper";
import {parseDate, toTitleCase} from "../../../utils/helpers/logicHelper";

const column: ColumnHelper<IStockAdjustment> = createColumnHelper<IStockAdjustment>()

export const StockAdjustmentCompactColumns: ColumnDef<IStockAdjustment, any>[] = [
  column.accessor('adjustment_date', {
    header: () => <span>Date</span>,
    cell: info => <span className="text-truncate">
            {(info.getValue() && isValid(parseDate(info.getValue())))
              ? <span>
                <span className="me-2">{format(parseDate(info.getValue()), "d LLLL, yyyy")}</span>
                <span className="ps-2 border-start border-gray-300"/>
                <br/>
                {format(parseDate(info.getValue()), "h:m a")}
            </span>
              : "-"
            }
        </span>,
    footer: info => info.column.id,
  }),
  column.accessor('code', {
    header: () => <span>Adjustment ID</span>,
    cell: info => <span className="text-truncate">{info.getValue() ?? '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('adjustment_type', {
    header: () => <span>Adjustment Type</span>,
    cell: info => <span className="text-truncate">{info.getValue() ? toTitleCase(info.getValue()) : '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('location_details.name', {
    header: () => <span>Business Location</span>,
    cell: info => <span className="text-truncate">{info.getValue() ? toTitleCase(info.getValue()) : '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('warehouse_details.name', {
    header: () => <span>Warehouse</span>,
    cell: info => <span className="text-truncate">{info.getValue() ? toTitleCase(info.getValue()) : '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('status', {
    header: () => <span>Status</span>,
    cell: info => <span className={`pointer text-start  
        ${(info.row && info.row.original && info.row.original["status"]) ? StockStatusMap[info.row.original["status"]].fill : "text-danger-500"}`}>
            {toTitleCase(info.getValue()) ?? ""}</span>,
    footer: info => info.column.id,
  }),
];
