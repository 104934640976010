import { IToastState } from '../../../types/store';
import Toast from './Toast';


type IToastListProps = { toasts: IToastState[], position: string };

const ToastList = ({ toasts, position = "top-right" }: IToastListProps) => {
	return (
		<div className={`custom-toast-container ${position}`}>
			{toasts.map((toast) => (
				<Toast key={toast.id} {...toast}/>
			))}
		</div>
	);
};

export default ToastList;
