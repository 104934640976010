import axios, { AxiosHeaders, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import TokenHelper from "../helpers/tokenHelper";
import CookieHelper from "../helpers/cookieHelper";
import { AppInfo, AppKeys } from "../helpers/constants";
import { Environment, MenuType, SubMenuType} from '../../types/enums';

const ENVIRONMENT_VARIABLES = process.env;

export const getBaseUrlEnv = () => {
  if (typeof window !== "undefined") {
    const hostname = window.location.hostname;

    if (hostname.endsWith('.phaneumdev.com')
      || hostname === ENVIRONMENT_VARIABLES.REACT_APP_LOCALHOST
      || hostname === ENVIRONMENT_VARIABLES.REACT_APP_VERCEL_HOSTNAME
      || hostname === ENVIRONMENT_VARIABLES.REACT_APP_STAGING_HOSTNAME
    ) return Environment.SANDBOX;
    else if (hostname.endsWith('.phaneum.com')) return Environment.PRODUCTION;
  }
};

export const getBaseUrl = (): IBaseUrl => {
  let backoffice: string = AppInfo.BACKOFFICE_PRODUCTION_BASEURL, hostname = window.location.hostname;

  if (hostname.endsWith('.phaneumdev.com')
    || hostname === ENVIRONMENT_VARIABLES.REACT_APP_LOCALHOST
    || hostname === ENVIRONMENT_VARIABLES.REACT_APP_VERCEL_HOSTNAME
    || hostname === ENVIRONMENT_VARIABLES.REACT_APP_STAGING_HOSTNAME) backoffice = AppInfo.BACKOFFICE_STAGING_BASEURL;
  else if (hostname.endsWith('.phaneum.com') || hostname === ENVIRONMENT_VARIABLES.REACT_APP_PRODUCTION_HOSTNAME) backoffice = AppInfo.BACKOFFICE_PRODUCTION_BASEURL;

  return { backoffice }
}

const headers = { "Content-Type": "application/json" };
const instance: AxiosInstance = axios.create({ baseURL: getBaseUrl()['backoffice'], headers });

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  let token = TokenHelper.getToken();

  if (TokenHelper.isAuthenticationValid()) {
    let headers = { Authorization: `JWT ${token}`, tenantURL: TokenHelper.getDomain(), ...config.headers};
    config.headers = headers as AxiosHeaders['headers'];
  }
  else config.headers = { ...config.headers } as AxiosHeaders['headers'];
  return config;
}, error => Promise.reject(error));

instance.interceptors.response.use((response: AxiosResponse) => response, error => {
  const token = TokenHelper.getToken();
  if (error && error.response && error.response.status === 403) window.location.href = '/403';
  else if (error && error.response && error.response.status === 401 && token) {
    CookieHelper.remove(ENVIRONMENT_VARIABLES.REACT_APP_KEY ?? AppKeys.TOKEN_KEY);
    CookieHelper.clear();
    window.location.href = `/${MenuType.AUTH}/${SubMenuType.VERIFY_DOMAIN}`
  } else return error.response;
});

export { instance };
