import {formatNumber} from "../../../utils/helpers/logicHelper";
import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";

const column: ColumnHelper<IStockLedger> = createColumnHelper<IStockLedger>()

export const StockLedgerExtensiveColumns: ColumnDef<IStockLedger, any>[] = [
  column.accessor('warehouse_name', {
    header: () => <span>Warehouse</span>,
    cell: info => <span className="text-truncate">{info.getValue() ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('incoming_rate', {
    header: () => <span>Incoming Rate</span>,
    cell: info => <span className="text-truncate">{formatNumber(info.getValue(), true) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('valuation_rate', {
    header: () => <span>Valuation Rate</span>,
    cell: info => <span className="text-truncate">{formatNumber(info.getValue(), true) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('balance_value', {
    header: () => <span>Balance Value</span>,
    cell: info => <span className="text-truncate">{formatNumber(info.getValue(), true) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('change_value', {
    header: () => <span>Change Value</span>,
    cell: info => <span className="text-truncate">{formatNumber(info.getValue(), true) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
];
