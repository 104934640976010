import { MenuType } from "../../types/enums";

export const AppInfo = {
  APP_NAME: "Phaneum Backoffice",
  ORG_NAME: 'Hobia HQ',
  BACKOFFICE_STAGING_BASEURL: "https://dev.backoffice.phaneum.com/api/phaneum/gateway",
  BACKOFFICE_PRODUCTION_BASEURL: "https://dev.backoffice.phaneum.com/api/phaneum/gateway",
};
export const AppKeys = {
  COOKIE_EXPIRY_PERIOD: 1,
  BUSINESS_INFO_TABS: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd",
  USER_KEY: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-n4Vk3484",
  TOKEN_KEY: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-70k3Nk3484",
  THEME_KEY: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-7H3m3k3484",
  USER_TYPE: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-u53R749384",
  DOMAIN: "DUMMY_94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-d0m41N0Rk3v3484",
  KEY: "DUMMY_94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-k3v3484c97H1anW4k43m3",
  SETTINGS_TABS: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-53771Ng57aB54c71v3k3v",
  CUSTOMER_SETTINGS_TABS: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-cU570m3R53771Ng57aB54c71v3k3v",
  TOKEN_DURATION_KEY: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-70k3NdnR4710Nk3484",
  DOMAIN_VERIFICATION_STATUS: "DUMMY_94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-d0m41Nv3R171c4710Nz747uz",
};

export const ColumnSettings = {
  items: {
    items: false,
    value: "",
    other: false,
    Products: false,
    Services: false
  },
  price: {
    rate: false,
    value: "",
    other: false,
    price: false
  },
  units: {
    hours: false,
    value: "",
    other: false,
    quantities: false
  },
  amount: {
    value: "",
    other: false,
    amount: false
  },
  choose_which_column_on_your_invoice_to_hide: {
    hide_item: false,
    hide_description: false
  },
  your_invoice_must_show_at_least_one_of_the_above: {
    hide_price: false,
    hide_amount: false,
    hide_quality: false
  }
}

export const DefaultSettings = {
  default_note: "",
  default_title: "",
  default_footer: "",
  default_subtitle: "",
  default_payment_terms: ""
}

export const initialData: IInitialPaginationProps = { data: [], count: 0 };


export const MenuTypeInitialStatus = {
  [MenuType.AUTH]: false,
  [MenuType.HOME]: false,
  [MenuType.USERS]: false,
  [MenuType.SUBSCRIPTIONS]: false,
  [MenuType.SETTINGS]: false,
  [MenuType.FEEDBACK]: false,
  [MenuType.SALES]: false,
  [MenuType.BANKING]: false,
  [MenuType.RESTAURANT_MANAGER]: false,
  [MenuType.INVENTORY]: false,
  [MenuType.PROCUREMENT]: false,
  [MenuType.CUSTOMER_MANAGEMENT]: false,
};

export const ModulesSettings = {
  procurement: {
    expense_manager: false,
    purchase_orders: false,
    suppliers_management: false
  },
  inventory_manager: {
    reports: false,
    stock_transfer: false,
    inventory_counts: false,
    stock_adjustments: false
  },
  resturant_manager: {
    menu_managers: false,
    resturant_orders: false,
    tables_and_region_manager: false
  },
  sales_and_payments: {
    orders: false,
    invoice: false,
    receipts: false,
    quotation: false,
    sales_return: false,
    resturant_orders: false
  },
  tenanat_management: {
    tenant_accounts: false,
    tenanat_roles_and_permissions: false
  },
  customer_management: {
    items: false,
    reports: false,
    categories: false,
    items_reports: false,
    pricing_tiers: false
  },
  banking_and_payments: {
    payments: false,
    bank_balance: false,
    expense_manager: false
  },
  user_and_subscription: {
    sunscription: false,
    user_profile: false,
    connected_devices: false
  },
  items_and_price_management: {
    items: false,
    reports: false,
    categories: false,
    items_reports: false,
    pricing_tiers: false
  }
}
