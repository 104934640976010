import {MenuType, StockStatusTypeEnums, SubMenuType} from "../../types/enums";

export const FileSamplesMap = {
  [SubMenuType.ITEMS]: "https://phaneum-staging-storage.nyc3.cdn.digitaloceanspaces.com/phaneum-staging-asset-storage/import_templates/ITEM_TEMPLATE.xlsx",
  [SubMenuType.CATEGORIES]: "https://phaneum-staging-storage.nyc3.cdn.digitaloceanspaces.com/phaneum-staging-asset-storage/import_templates/CATEGORY_TEMPLATE.xlsx",
  [SubMenuType.STOCK_TRANSFER]: "https://phaneum-staging-storage.nyc3.cdn.digitaloceanspaces.com/phaneum-staging-asset-storage/import_templates/CATEGORY_TEMPLATE.xlsx",
  [MenuType.CUSTOMER_MANAGEMENT]: "https://phaneum-staging-storage.nyc3.cdn.digitaloceanspaces.com/phaneum-staging-asset-storage/import_templates/CUSTOMER_TEMPLATE.xlsx",
};

export const StockStatusMap: {[key: string]: {color: string, fill: string}} = {
  [StockStatusTypeEnums.COMPLETED]: {
    color: "#0F973D",
    fill: "text-success-400"
  },
  [StockStatusTypeEnums.CANCELLED]: {
    color: "#CB1A14",
    fill: "text-danger-500"
  },
  [StockStatusTypeEnums.SUBMITTED]: {
    color: "#1671D9",
    fill: "text-secondary-400"
  },
  [StockStatusTypeEnums.DRAFT]: {
    color: "#DD900D",
    fill: "text-warning-500"
  },
  [StockStatusTypeEnums.PARTIAL]: {
    color: "#F56630",
    fill: "text-primary-400"
  },
};
