import { backOfficeBaseQuery } from "./backoffice.slice";
import { createApi } from "@reduxjs/toolkit/query/react";


export const customerQuery = createApi({
  baseQuery: backOfficeBaseQuery,
  reducerPath: 'customerQuery',
  tagTypes: ['customer_management', 'customer_credit', 'credit_settings', 'feedback_settings'],
  endpoints: (builder) => ({
    /** CRM */
    getCustomers: builder.query({
      query: (filter) => ({ url: `/crm/customer/profile/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['customer_management']
    }),
    addCustomer: builder.mutation({
      query: ({payload, header}) => {
        let headers =  header ? {...header } : {};
        return ({ url: `/crm/customer/profile/`, method: "POST", body: payload, headers })
      },
      invalidatesTags: ['customer_management']
    }),
    importCustomers: builder.mutation({
      query: ({ id, payload }) => ({ url: `/crm/customer/profile/${id}/import_customer_profile/`, method: "POST", body: payload }),
      invalidatesTags: ['customer_management']
    }),
    uploadCustomerPicture: builder.mutation<IApiResponse<ICustomer>, { id: string | undefined, payload: { profile_image: string } }>({
      query: ({ id, payload }) => {
        return ({ url: `/crm/customer/profile/${id}/update_profile_picture/`, method: "POST", body: payload, headers: { images: "['profile_image']"} })
      },
      invalidatesTags: ['customer_management']
    }),
    updateCustomer: builder.mutation({
      query: ({ id, payload }) => ({ url: `/crm/customer/profile/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['customer_management']
    }),
    toggleCustomerProfileStatus: builder.mutation({
      query: ({ id, suffix }) => ({ url: `/crm/customer/profile/${id}/${suffix}/`, method: "GET" }),
      invalidatesTags: ['customer_management']
    }),
    exportCustomers: builder.mutation<IApiResponse<ICustomer>, { id: string, payload: Partial<ICustomer>[]}>({
      query: ({ id, payload }) => ({
        url: `/crm/customer/profile/${id}/export_customer_profile/`,
        method: "POST", body: payload, headers: {admin: "admin" }
      })
    }),
    sendCreditStatement: builder.mutation({
      query: id => ({
        url: `/crm/customer/profile/${id}/send_customer_credit_statement/`, method: "GET"
      })
    }),
    addCustomerNote: builder.mutation({
      query: ({ id, payload }) => ({ url: `/crm/customer/profile/${id}/add_customer_note/`, method: "POST", body: payload }),
      invalidatesTags: ['customer_management']
    }),

    getCustomerCredit: builder.query({
      query: (filter) => ({ url: `/crm/customer/credit/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['customer_credit']
    }),
    addCustomerCredit: builder.mutation({
      query: payload => ({ url: `/crm/customer/credit/`, method: "POST", body: payload }),
      invalidatesTags: ['customer_credit']
    }),
    updateCustomerCredit: builder.mutation({
      query: ({ id, payload }) => ({ url: `/crm/customer/credit/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['customer_credit']
    }),


    getCreditSettings: builder.query({
      query: (filter) => ({ url: `/crm/customer/tenant-credit-settings/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['credit_settings']
    }),
    addCreditSettings: builder.mutation({
      query: payload => ({ url: `/crm/customer/tenant-credit-settings/`, method: "POST", body: payload }),
      invalidatesTags: ['credit_settings']
    }),
    updateCreditSettings: builder.mutation({
      query: ({ id, payload }) => ({ url: `/crm/customer/tenant-credit-settings/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['credit_settings']
    }),
    getFeedbackSettings: builder.query({
      query: (filter) => ({ url: `/crm/customer/tenant-feedback-settings/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['feedback_settings']
    }),
    addFeedbackSettings: builder.mutation({
      query: payload => ({ url: `/crm/customer/tenant-feedback-settings/`, method: "POST", body: payload }),
      invalidatesTags: ['feedback_settings']
    }),
    updateFeedbackSettings: builder.mutation({
      query: ({ id, payload }) => ({ url: `/crm/customer/tenant-feedback-settings/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['feedback_settings']
    }),
  })
});

export const {
  useGetCustomersQuery,
  useAddCustomerMutation,
  useImportCustomersMutation,
  useUploadCustomerPictureMutation,
  useUpdateCustomerMutation,
  useToggleCustomerProfileStatusMutation,
  useExportCustomersMutation,
  useSendCreditStatementMutation,
  useAddCustomerNoteMutation,
  useGetCustomerCreditQuery,
  useAddCustomerCreditMutation,
  useUpdateCustomerCreditMutation,
  useGetCreditSettingsQuery,
  useAddCreditSettingsMutation,
  useUpdateCreditSettingsMutation,
  useGetFeedbackSettingsQuery,
  useAddFeedbackSettingsMutation,
  useUpdateFeedbackSettingsMutation,
} = customerQuery;