import { backOfficeBaseQuery } from "./backoffice.slice";
import { createApi } from "@reduxjs/toolkit/query/react";


export const configurationQuery = createApi({
  baseQuery: backOfficeBaseQuery,
  reducerPath: 'configurationQuery',
  tagTypes: [
    'currencies', 'notification_settings', 'payment_methods', 'print_template', 'roles', 'tax'
  ],
  endpoints: (builder) => ({
    /** CONFIGURATIONS */
    getPaymentMethod: builder.query({
      query: (filter) => ({ url: `/tenancy-management/tenant-payment-method/${filter}`, headers: {admin: "admin"} }),
      providesTags: ['payment_methods']
    }),
    addPaymentMethod: builder.mutation({
      query: body => ({ url: `/tenancy-management/tenant-payment-method/`, method: "POST", body }),
      invalidatesTags: ['payment_methods']
    }),
    updatePaymentMethod: builder.mutation({
      query: ({ id, payload }) => ({ url: `/tenancy-management/tenant-payment-method/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['payment_methods']
    }),
    togglePaymentMethodStatus: builder.mutation({
      query: ({ id, suffix }) => ({ url: `/tenancy-management/tenant-payment-method/${id}/${suffix}/`, method: "GET" }),
      invalidatesTags: ['payment_methods']
    }),

    getCurrencies: builder.query({
      query: (filter) => ({ url: `/tenancy-management/tenant-payment-currency/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['currencies']
    }),
    addCurrency: builder.mutation({
      query: body => ({ url: `/tenancy-management/tenant-payment-currency/`, method: "POST", body }),
      invalidatesTags: ['currencies']
    }),
    updateCurrency: builder.mutation({
      query: ({ id, payload }) => ({ url: `/tenancy-management/tenant-payment-currency/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['currencies']
    }),
    toggleCurrencyStatus: builder.mutation({
      query: ({ id, suffix }) => ({ url: `/tenancy-management/tenant-payment-currency/${id}/${suffix}/`, method: "GET" }),
      invalidatesTags: ['currencies']
    }),

    getTax: builder.query({
      query: (filter) => ({ url: `/tenancy-management/tenant-tax/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['tax']
    }),
    addTax: builder.mutation({
      query: body => ({ url: `/tenancy-management/tenant-tax/`, method: "POST", body }),
      invalidatesTags: ['tax']
    }),
    updateTax: builder.mutation({
      query: ({ id, payload }) => ({ url: `/tenancy-management/tenant-tax/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['tax']
    }),
    toggleTaxStatus: builder.mutation({
      query: ({ id, suffix }) => ({ url: `/tenancy-management/tenant-tax/${id}/${suffix}/`, method: "GET" }),
      invalidatesTags: ['tax']
    }),

    getEmailNotificationSetting: builder.query({
      query: () => ({ url: `/tenancy-management/tenant-notification/`, headers: {admin: "admin" } }),
      providesTags: ['notification_settings']
    }),
    addEmailNotificationSetting: builder.mutation({
      query: body => ({ url: `/tenancy-management/tenant-notification/`, method: "POST", body }),
      invalidatesTags: ['notification_settings']
    }),
    updateEmailNotificationSetting: builder.mutation({
      query: ({ id, payload }) => ({ url: `/tenancy-management/tenant-notification/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['notification_settings']
    }),

    getPrintTemplate: builder.query({
      query: () => ({ url: `/tenancy-management/tenant-print-template/`, headers: {admin: "admin" } }),
      providesTags: ['print_template']
    }),
    addPrintTemplate: builder.mutation({
      query: ({ payload, header}) => {
        let headers = {};
        if(header) headers = {...header};
        return({ url: `/tenancy-management/tenant-print-template/`, method: "POST", body: payload, headers })
      },
      invalidatesTags: ['print_template']
    }),
    uploadBusinessLogo: builder.mutation<IApiResponse<IPrintTemplate>, { id: string | undefined, payload: {logo: string} }>({
      query: ({ id, payload }) => {
        return ({ url: `/tenancy-management/tenant-print-template/${id}/update_print_logo/`, method: "POST", body: payload, headers: { images: "['logo']"} })
      },
      invalidatesTags: ['print_template']
    }),
    updatePrintTemplate: builder.mutation({
      query: ({ id, payload }) => ({ url: `/tenancy-management/tenant-print-template/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['print_template']
    }),
    removeLogo: builder.mutation<IApiResponse<IPrintTemplate>, string>({
      query: id => ({ url: `/tenancy-management/tenant-print-template/${id}/remove_print_logo/`, method: "GET" }),
      invalidatesTags: ['print_template']
    }),
  })
});

export const {
  useGetPaymentMethodQuery,
  useAddPaymentMethodMutation,
  useUpdatePaymentMethodMutation,
  useTogglePaymentMethodStatusMutation,

  useGetCurrenciesQuery,
  useAddCurrencyMutation,
  useUpdateCurrencyMutation,
  useToggleCurrencyStatusMutation,

  useGetTaxQuery,
  useAddTaxMutation,
  useUpdateTaxMutation,
  useToggleTaxStatusMutation,

  useGetEmailNotificationSettingQuery,
  useAddEmailNotificationSettingMutation,
  useUpdateEmailNotificationSettingMutation,

  useGetPrintTemplateQuery,
  useAddPrintTemplateMutation,
  useUploadBusinessLogoMutation,
  useUpdatePrintTemplateMutation,
  useRemoveLogoMutation,
} = configurationQuery;
