import CryptoJS from 'crypto-js';
import { AppKeys } from './constants';
import { setKey } from './logicHelper';

const ENVIRONMENT_VARIABLES = process.env;

const EncryptionHelper = {
  encrypt: <T>(data: T): CryptoJS.lib.CipherParams | undefined => {
    const updatedKey = setKey(ENVIRONMENT_VARIABLES.REACT_APP_KEY ?? AppKeys.TOKEN_KEY);
    if (!data) return;
    else return CryptoJS.AES.encrypt(JSON.stringify(data), updatedKey);
  },

  decrypt: (data: string | null) => {
    const updatedKey = setKey(ENVIRONMENT_VARIABLES.REACT_APP_KEY ?? AppKeys.TOKEN_KEY);
    if (!data) return null;
    else {
      const bytes = CryptoJS.AES.decrypt(data.toString(), updatedKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  },
};

export default EncryptionHelper;