import { ReactElement } from "react";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import AppLoader from "../loaders/AppLoader";
import { IStoreState } from "../../types/store";
import LogoSVG from "../../assets/images/pngs/logo-black.png";
import TwitterSVG from "../../assets/images/svgs/icons/twitter.svg";
import FacebookSVG from "../../assets/images/svgs/icons/facebook.svg";
import LinkedInSVG from "../../assets/images/svgs/icons/linkedIn.svg";


type IProps = {
	image: string,
	title: string,
	message: string,
	contentClass?: string,
	children: ReactElement
	customBackgroundColor?: string,
}

const ErrorLayout = ({ image, title, message, children, contentClass }: IProps) => {
	const appLoader = useSelector((state: IStoreState) => state.component.appLoader);

	return (
		<div className="page bg-white min-vh-100">
			{appLoader ? <AppLoader /> :
				<div className="container d-flex flex-column justify-content-between py-3 py-md-5 min-vh-100">
					<div>
						<Image loading="lazy" src={LogoSVG} alt="LOGO" className="object-fit-contain mb-0 mb-md-3 mb-lg-5" width={110} height={20} />
						<div className="row mt-4">
							<div className="col-md-12 col-lg-5 col-xxl-6 my-0 my-md-2">
								<Image loading="lazy" src={image} alt="LOGO" className="d-block mx-auto object-fit-contain" />
							</div>
							<div className="col-md-12 col-lg-7 col-xxl-6">
								<div className="d-flex justify-content-center align-content-center m-1 m-xl-5 p-0 p-lg-5">
									<div className={`text-center ${contentClass ? contentClass : "mt-3 mt-md-4 mt-lg-5"}`}>
										<h3 className="tx-48 text-gray-700 mb-1">{title}</h3>
										<small className="text-gray-500 mx-1">{message}</small>
										<div className="mt-4">{children}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column flex-md-row justify-content-center row-gap-3 column-gap-5 text-center text-office-brown-700 mt-5">
						<small>&copy; Phaneum 2023</small>
						<small>All-in-one business management solution</small>
						<div className="d-inline-flex justify-content-center column-gap-1">
							<Image loading="lazy" src={FacebookSVG} alt="LOGO" className="object-fit-contain" width={18} height={18} />
							<Image loading="lazy" src={TwitterSVG} alt="LOGO" className="object-fit-contain" width={18} height={18} />
							<Image loading="lazy" src={LinkedInSVG} alt="LOGO" className="object-fit-contain" width={20} height={20} />
						</div>
					</div>
				</div>
			}
		</div>
	)
}

export default ErrorLayout;
