import { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ToastTypes } from "../../../types/enums";
import { ToastObject } from "../../../utils/helpers/objectHelper";
import { removeToast } from "../../../store/slices/component.slice";

type Props = {
	id: string,
	title: string,
	message: string,
	type: ToastTypes,
}

const Toast = ({ title, message, type, id }: Props) => {
	const dispatch = useDispatch();
	const toastType = ToastObject[type];
	const toastRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const progressRef = useRef<HTMLDivElement>(null);
	const [dismissed, setDismissed] = useState<boolean>(false);


	useEffect(() => {
		toastRef.current = setTimeout(() => { handleDismiss(); }, 5000);

		return () => {
			if (toastRef.current) clearTimeout(toastRef.current);
		};
	}, []);

	const handleDismiss = () => {
		setDismissed(true);
		setTimeout(() => removeToast(dispatch, id), 5000);
	};

	const handleMouseEnter = () => {
		if (toastRef.current) clearTimeout(toastRef.current);
		if (progressRef.current) progressRef.current.style.animationPlayState = 'paused';
	};

	const handleMouseLeave = () => {
		if (progressRef.current && progressRef.current.parentElement) {
			const remainingTime = (progressRef.current.offsetWidth / progressRef.current.parentElement.offsetWidth) * 5000;
			progressRef.current.style.animationPlayState = 'running';

			toastRef.current = setTimeout(() => handleDismiss(), remainingTime);
		}
	};

	return (
		<div
			className={`custom-toast ${toastType.borderClass} ${dismissed ? 'custom-toast-dismissed' : ''}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}>
			<div className="row">
				<div className="col-10">
					<div className="d-flex">
						<Image loading="lazy" src={toastType.imgSource} alt={`${type.toUpperCase()}-ICON`} />
						<div>
							{title && <h6>{title}</h6>}
							{message && <p>{message}</p>}
						</div>
					</div>
				</div>
				<div className="col-2 border-start mt-1">
					<button className="btn-close" onClick={handleDismiss} />
				</div>
			</div>

			<div className="toast-progress">
				<div
					ref={progressRef}
					className={`progress-bar progress-bar-xs progress-bar-striped progress-bar-animated toast-progress-bar ${toastType.progressBarClass}`}
				/>
			</div>
		</div>
	)
}

export default Toast;
