import { setKey } from "./logicHelper";
import EncryptionHelper from "./encryptionHelper";

const CookieHelper = {
  get: (key: string) => {

    if (!key) return;
    const dataEncrypted = localStorage.getItem(setKey(key));
    return dataEncrypted ? EncryptionHelper.decrypt(dataEncrypted) : '';
  },

  set: <T>(key: string, data: T) => {
    if (!key) return;    
    const dataEncrypted = EncryptionHelper.encrypt(data) as unknown as string;    
    localStorage.setItem(setKey(key), dataEncrypted);
  },

  remove: (key: string) => {
    if (!key) return;
    localStorage.removeItem(setKey(key))
  },

  clear: () => localStorage.clear()
}

export default CookieHelper;