import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {format, isValid} from "date-fns";
import {formatNumber, parseDate, toTitleCase} from "../../../utils/helpers/logicHelper";

const column: ColumnHelper<IStockLedger> = createColumnHelper<IStockLedger>()

export const StockLedgerCompactColumns: ColumnDef<IStockLedger, any>[] = [
  column.accessor('added_on', {
    header: () => <span>Date</span>,
    cell: info => <span className="text-truncate">
            {(info.getValue() && isValid(parseDate(info.getValue())))
              ? <span>
                <span className="me-2">{format(parseDate(info.getValue()), "d LLLL, yyyy")}</span>
                <span className="ps-2 border-start border-gray-300"/>
                <br/>
                {format(parseDate(info.getValue()), "h:m a")}
            </span>
              : "-"
            }
        </span>,
    footer: info => info.column.id,
  }),
  column.accessor('item_name', {
    header: () => <span>Item</span>,
    cell: info => <span className="text-truncate">
            <span className="fw-bold">{info.getValue() ?? "-"}</span>
      <span className="d-block text-gray-500">
        {(info.row.original && info.row.original.item_code) ? info.row.original.item_code.toUpperCase() : ""}
      </span>
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('transaction_id', {
    header: () => <span>Transaction ID</span>,
    cell: info => <span className="text-truncate">{info.getValue() ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('transaction_type', {
    header: () => <span>Transaction Type</span>,
    cell: info => <span className="text-truncate">{toTitleCase(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('quantity_in', {
    header: () => <span>Qty In</span>,
    cell: info => <span className="text-truncate">{formatNumber(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('quantity_out', {
    header: () => <span>Qty Out</span>,
    cell: info => <span className="text-truncate">
      {formatNumber(info.getValue()) ?? "-"}
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('balance_quantity', {
    header: () => <span>Balance</span>,
    cell: info => <span className="text-truncate">{formatNumber(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  })
];

