import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {format, isValid} from "date-fns";
import {StockStatusMap} from "../../../utils/dataStore/mapper";
import {convertNumberToShortForm, parseDate, toTitleCase} from "../../../utils/helpers/logicHelper";

const column: ColumnHelper<IStockTransfer> = createColumnHelper<IStockTransfer>()

export const StockTransferExtensiveColumns: ColumnDef<IStockTransfer, any>[] = [
  column.accessor('added_on', {
    header: () => <span>Date</span>,
    cell: info => <span className="text-truncate">
            {(info.getValue() && isValid(parseDate(info.getValue())))
              ? <span>
                <span className="me-2">{format(parseDate(info.getValue()), "d LLLL, yyyy")}</span>
                <span className="ps-2 border-start border-gray-300"/>
                <br/>
                {format(parseDate(info.getValue()), "h:m a")}
            </span>
              : "-"
            }
        </span>,
    footer: info => info.column.id,
  }),
  column.accessor('code', {
    header: () => <span>Transfer ID</span>,
    cell: info => <span className="text-truncate">{info.getValue() ? info.getValue() : '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('sending_location_details.name', {
    header: () => <span>Sending Location</span>,
    cell: info => <span className="text-truncate">{info.getValue() ? toTitleCase(info.getValue()) : '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('receiving_location_details.name', {
    header: () => <span>Receiving Location</span>,
    cell: info => <span className="text-truncate">{info.getValue() ? toTitleCase(info.getValue()) : '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('items', {
    header: () => <span>Item Quantity</span>,
    cell: info => <span className="d-flex justify-content-center">
            <span className="badge badge-primary text-gray-900 py-2 px-3">
                {convertNumberToShortForm(info.getValue() as string)}
            </span>
        </span>,
    footer: info => info.column.id,
  }),
  column.accessor('status', {
    header: () => <span>Status</span>,
    cell: info => <span className={`pointer text-start  
        ${(info.row && info.row.original && info.row.original["status"]) ? StockStatusMap[info.row.original["status"]].fill : "text-danger-500"}`}>
            {toTitleCase(info.getValue()) ?? ""}</span>,
    footer: info => info.column.id,
  }),
];
