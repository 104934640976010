import CookieHelper from "./cookieHelper";
import { AppKeys } from "./constants";
import { AppDomainStatus, CustomerNavEnums, SettingsNavEnums } from "../../types/enums";

const ENVIRONMENT_VARIABLES = process.env;

const TokenHelper = {

  isAuthenticationValid(): boolean {
    const TOKEN = CookieHelper.get(ENVIRONMENT_VARIABLES.REACT_APP_TOKEN_KEY ?? "");
    return !!TOKEN;
  },

  saveToken(token: string): void {
    CookieHelper.set(ENVIRONMENT_VARIABLES.REACT_APP_TOKEN_KEY ?? AppKeys.TOKEN_KEY, token);
  },

  getToken(): string {
    return CookieHelper.get(ENVIRONMENT_VARIABLES.REACT_APP_TOKEN_KEY ?? AppKeys.TOKEN_KEY);
  },

  saveDomain(domain: string): void {
    CookieHelper.set(ENVIRONMENT_VARIABLES.REACT_APP_DOMAIN ?? AppKeys.DOMAIN, domain);
  },

  getDomain(): string {
    return CookieHelper.get(ENVIRONMENT_VARIABLES.REACT_APP_DOMAIN ?? AppKeys.DOMAIN);
  },

  saveDomainStatus(domain: AppDomainStatus): void {
    CookieHelper.set(ENVIRONMENT_VARIABLES.REACT_APP_DOMAIN_VERIFICATION_STATUS ?? AppKeys.DOMAIN_VERIFICATION_STATUS, domain);
  },

  getDomainStatus(): string {
    return CookieHelper.get(ENVIRONMENT_VARIABLES.REACT_APP_DOMAIN_VERIFICATION_STATUS ?? AppKeys.DOMAIN_VERIFICATION_STATUS);
  },

  saveUser(data: IAuthenticationUser): void {
    CookieHelper.set(ENVIRONMENT_VARIABLES.REACT_APP_USER_KEY ?? AppKeys.USER_KEY, data);
  },

  getUser(): IAuthenticationUser {
    return CookieHelper.get(ENVIRONMENT_VARIABLES.REACT_APP_USER_KEY ?? AppKeys.USER_KEY);
  },

  saveBusinessInfoTab(data: string): void {
    CookieHelper.set(ENVIRONMENT_VARIABLES.REACT_APP_BUSINESS_INFO_TABS ?? AppKeys.BUSINESS_INFO_TABS, data);
  },

  getBusinessInfoTab<T>(): T {
    return CookieHelper.get(ENVIRONMENT_VARIABLES.BUSINESS_INFO_TABS ?? AppKeys.BUSINESS_INFO_TABS);
  },

  saveSettingsTab(data: SettingsNavEnums): void {
    CookieHelper.set(ENVIRONMENT_VARIABLES.REACT_APP_SETTINGS_TABS ?? AppKeys.SETTINGS_TABS, data);
  },

  getSettingsTab(): SettingsNavEnums {
    return CookieHelper.get(ENVIRONMENT_VARIABLES.REACT_APP_SETTINGS_TABS ?? AppKeys.SETTINGS_TABS);
  },

  saveCustomerSettingsTab(data: CustomerNavEnums): void {
    CookieHelper.set(ENVIRONMENT_VARIABLES.REACT_APP_CUSTOMER_SETTINGS_TABS ?? AppKeys.CUSTOMER_SETTINGS_TABS, data);
  },

  getCustomerSettingsTab(): CustomerNavEnums {
    return CookieHelper.get(ENVIRONMENT_VARIABLES.REACT_APP_CUSTOMER_SETTINGS_TABS ?? AppKeys.CUSTOMER_SETTINGS_TABS);
  },
}

export default TokenHelper;
