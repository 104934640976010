import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {formatNumber} from "../../../utils/helpers/logicHelper";


const column: ColumnHelper<IBalanceValuation> = createColumnHelper<IBalanceValuation>()

export const LowStockItemsExtensiveColumns: ColumnDef<IBalanceValuation, any>[] = [
  column.accessor('item_name', {
    header: () => <span>Item</span>,
    cell: info => <span className="text-truncate">
          <span className="fw-bold">{info.getValue() ?? "-"}</span>
      <span className="d-block text-gray-500">
        {(info.row.original && info.row.original.item_code) ? info.row.original.item_code.toUpperCase() : ""}
      </span>
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('quantity', {
    header: () => <span>Available Qty</span>,
    cell: info => <span className="text-truncate">{formatNumber(info.getValue(), true) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('total_value', {
    header: () => <span>Total Value</span>,
    cell: info => <span className="text-truncate">{formatNumber(info.getValue(), true) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
];
