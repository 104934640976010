import {useNavigate} from "react-router-dom";
import ErrorSVG from "../../assets/images/svgs/error-403.svg";
import ErrorLayout from "../../components/layouts/ErrorLayout";

const Error403 = () => {
	const navigate = useNavigate();

	return (
			<ErrorLayout image={ErrorSVG} title="403 - Access Denied" contentClass="mt-4 mt-lg-5"
									 message="The page you’re trying to access has restricted access. Please contact your system administrator.">
				<button className="btn btn-primary btn-sm d-block mx-auto px-3" onClick={() => navigate(-1)}>Go Back</button>
			</ErrorLayout>
	)
}

export default Error403;
