import { backOfficeBaseQuery } from "./backoffice.slice";
import { createApi } from "@reduxjs/toolkit/query/react";


export const salesQuery = createApi({
  baseQuery: backOfficeBaseQuery,
  reducerPath: 'salesQuery',
  tagTypes: ['feedbacks', 'transactions',],
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: (filter) => ({ url: `/sales/transaction/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['transactions']
    }),
    getCustomerCreditHistory: builder.mutation({
      query: ({ id, filter }) => ({
        url: `/sales/transaction/?order=ASC&customer__id=${id}&payment_type=CREDIT${filter}`,
        method: "GET", headers: {admin: "admin" }
      })
    }),
    addTransactionNote: builder.mutation({
      query: ({ id, payload }) => ({ url: `/sales/transaction/${id}/add_sales_note/`, method: "POST", body: payload }),
      invalidatesTags: ['transactions']
    }),
    updateTransactionRepayment: builder.mutation({
      query: (payload) => ({ url: `/sales/transaction/record_transaction_repayment/`, method: "POST", body: payload }),
      invalidatesTags: ['transactions']
    }),
    updateMultipleTransactionRepayment: builder.mutation({
      query: (payload) => ({ url: `/sales/transaction/record_multiple_transaction_repayment/`, method: "POST", body: payload }),
      invalidatesTags: ['transactions']
    }),
    getCustomerFeedbacks: builder.query({
      query: (filter) => ({ url: `/sales/transaction-feedback/customer_feedback_only/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['feedbacks']
    }),
    getFeedbacks: builder.query({
      query: (filter) => ({ url: `/sales/transaction-feedback/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['feedbacks']
    }),
    addFeedbackNote: builder.mutation({
      query: ({ id, payload }) => ({ url: `/sales/transaction-feedback/${id}/add_feedback_note/`, method: "POST", body: payload }),
      invalidatesTags: ['feedbacks']
    }),
    replyFeedback: builder.mutation({
      query: ({ id, payload }) => ({ url: `/sales/transaction-feedback/${id}/reply_transaction_feedback/`, method: "POST", body: payload }),
      invalidatesTags: ['feedbacks']
    }),
  })
});

export const {
  useGetTransactionsQuery,
  useGetCustomerCreditHistoryMutation,
  useAddTransactionNoteMutation,
  useUpdateTransactionRepaymentMutation,
  useUpdateMultipleTransactionRepaymentMutation,
  useGetCustomerFeedbacksQuery,
  useGetFeedbacksQuery,
  useAddFeedbackNoteMutation,
  useReplyFeedbackMutation,
} = salesQuery;