import { ReactElement, createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import ToastList from "./toasts/ToastList";
import { Dispatch } from "@reduxjs/toolkit";
import { ToastTypes } from "../../types/enums";
import { addToast } from "../../store/slices/component.slice";
import { IStoreState, ToastContextProps } from "../../types/store";


type ToastContextProviderProps = {
	children: ReactElement,
	position: string
}

export const ToastContext = createContext<ToastContextProps>({
	success: (_title, _message) => { },
	error: (_title, _message) => { },
});


export const ToastContextProvider = ({ children, position }: ToastContextProviderProps) => {
	const dispatch: Dispatch = useDispatch();
	const toasts = useSelector((state: IStoreState) => state.component.toasts);

	const handleAddToast = (type: ToastTypes, title: string, message: string) => {
		const id = Math.floor(Math.random() * 1000000).toString().concat("_", Date.now().toString());
		addToast(dispatch, { id, title, message, type });
	}


	const success = (title: string, message: string) => {
		handleAddToast(ToastTypes.SUCCESS, title, message);
	};

	const error = (title: string, message: string) => {
		handleAddToast(ToastTypes.ERROR, title, message);
	};

	const value = { success, error };

	return (
		<ToastContext.Provider value={value}>
			<ToastList toasts={toasts} position={position} />
			{children}
		</ToastContext.Provider>
	);
};

export const useToast = () => useContext(ToastContext);
