import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {AppKeys} from "../../utils/helpers/constants";
import {MenuType, SubMenuType} from "../../types/enums";
import TokenHelper from '../../utils/helpers/tokenHelper';
import CookieHelper from "../../utils/helpers/cookieHelper";
import { getBaseUrl } from '../../utils/services/backoffice.service';

const initializeBaseQuery = fetchBaseQuery({
  baseUrl: getBaseUrl()['backoffice'],

  prepareHeaders: (headers) => {
    const token = TokenHelper.getToken();
    const domain = TokenHelper.getDomain();
    headers.set("Content-Type", 'application/json');

    if (token) headers.set("Authorization", `JWT ${token}`);
    if (domain) headers.set("tenantURL", domain);

    return headers;
  },
}) as BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export const backOfficeBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const token = TokenHelper.getToken();
  let result = await initializeBaseQuery(args, api, extraOptions);

  if (result && result.error && result.error.status === 403) window.location.href = '/403';
  else if (result && result.error && result.error.status === 401 && token) {
    CookieHelper.remove(process.env.REACT_APP_KEY ?? AppKeys.TOKEN_KEY);
    CookieHelper.clear();
    window.location.href = `/${MenuType.AUTH}/${SubMenuType.VERIFY_DOMAIN}`
  }

  return result;
};

export const backOfficeQuery = createApi({
  baseQuery: backOfficeBaseQuery,
  reducerPath: 'backOfficeQuery',
  tagTypes: [],
  endpoints: (builder) => ({
    /** SYSTEM | ADMIN CONFIGURED DATA */
    getSupportedAddons: builder.query({
      query: () => `/subscriptions/addons/`
    }),
    getSupportedPlans: builder.query({
      query: () => `/subscriptions/system-subscriptions/`
    }),
    getSupportedCurrencies: builder.query({
      query: () => `/configDataStore/currency/`
    }),
    getSupportedPaymentMethods: builder.query({
      query: () => `/configDataStore/payment-method/`
    }),
    getSupportedStates: builder.query({
      query: () => `/configDataStore/state/`
    }),
    getSupportedCountries: builder.query({
      query: () => `/configDataStore/country/`
    }),
  })
})

export const {
  useGetSupportedAddonsQuery,
  useGetSupportedPlansQuery,
  useGetSupportedCurrenciesQuery,
  useGetSupportedPaymentMethodsQuery,
  useGetSupportedStatesQuery,
  useGetSupportedCountriesQuery
} = backOfficeQuery;
