import ErrorSVG from "../../assets/images/svgs/toast-error.svg";
import SuccessSVG from "../../assets/images/svgs/toast-success.svg";
import {
  LENGTH_REGEX, LOWERCASE_REGEX, NUMBER_REGEX, SPECIAL_CHARS_REGEX, UPPERCASE_REGEX
} from "../services/validator.service";
import {
  AddonEnums, AdminRoleEnums, ColumnSettingsParentKey,
  CustomTimeType, DefaultTimeType, EmailNotificationSettingKey,
  FeedbacksTypeEnum, LayoutViewOptions, PaginationOptions,
  PaymentMethodEnums, RatingsEnums, ReconciliationTypeEnums,
  SortOptions, StatFilterEnums, StockAdjustmentTypeEnums,
  StockStatusTypeEnums, SubscriptionDurationEnums,
  TaxTypeEnums, TemplateTypeEnums, TimeType
} from "../../types/enums";


/*** ARRANGED IN ALPHABETICAL ORDER ***/
export const AddonTypes = [
  {label: "User", value: AddonEnums.USER},
  {label: "Device", value: AddonEnums.DEVICE},
  {label: "Location", value: AddonEnums.LOCATION},
];

export const AdminUsersRoles = [
  {label: "Service Staff", value: AdminRoleEnums.SERVICE_STAFF},
  {label: "Management Staff", value: AdminRoleEnums.MANAGEMENT_STAFF},
];

export const AnalyticsTimeRange: IAnalyticsTimeRange[] = [
  {label: 'Today', value: DefaultTimeType.TODAY, type: TimeType.DEFAULT},
  {label: 'Yesterday', value: DefaultTimeType.YESTERDAY, type: TimeType.DEFAULT},
  {label: 'Current Week', value: DefaultTimeType["CURRENT-WEEK"], type: TimeType.DEFAULT},
  {label: 'Last Week', value: DefaultTimeType["LAST-WEEK"], type: TimeType.DEFAULT},
  {label: 'Current Month', value: DefaultTimeType["THIS-MONTH"], type: TimeType.DEFAULT},
  {label: 'Last Month', value: DefaultTimeType["LAST-MONTH"], type: TimeType.DEFAULT},
  {label: 'Current Year', value: DefaultTimeType["THIS-YEAR"], type: TimeType.DEFAULT},
  {label: 'Last Year', value: DefaultTimeType["LAST-YEAR"], type: TimeType.DEFAULT},
  {label: 'Month', value: CustomTimeType.MONTH, type: TimeType.CUSTOM},
  {label: 'Quarter', value: CustomTimeType.QUARTER, type: TimeType.CUSTOM},
  {label: 'Half Year', value: CustomTimeType.HALF_YEAR, type: TimeType.CUSTOM},
  {label: 'Year', value: CustomTimeType.YEAR, type: TimeType.CUSTOM},
  {label: 'Custom Range', value: CustomTimeType.CUSTOM_RANGE, type: TimeType.CUSTOM},
];

export const EmailNotifications = [
  {
    key: EmailNotificationSettingKey.DAILY_SALES,
    title: "Daily sales summary",
    subTitle: "Send updates on daily sales for my business"
  },
  {
    key: EmailNotificationSettingKey.ANNUAL_SALES,
    title: "Monthly and annual sales summaries",
    subTitle: "Send updates on my monthly and annual sales summaries for my business"
  },
  {
    key: EmailNotificationSettingKey.INVENTORY,
    title: "Inventory Alerts",
    subTitle: "Receive alert on inventory intakes and updates"
  },
  {
    key: EmailNotificationSettingKey.PRODUCTS,
    title: "Product updates",
    subTitle: "Send mails on new changes and updates on Phaneum"
  },
  {
    key: EmailNotificationSettingKey.PROGRAMS_EVENT,
    title: "Upcoming programs and events for businesses",
    subTitle: "Get updates on conferences, webinars and events organized for businesses"
  },
];

export const FeedbackType = [
  {label: "General Feedback", value: FeedbacksTypeEnum.GENERAL},
  {label: "Transaction Feedback", value: FeedbacksTypeEnum.TRANSACTION},
];

export const FrequencyType = [
  {label: "Monthly", value: SubscriptionDurationEnums.MONTHLY},
  {label: "Yearly", value: SubscriptionDurationEnums.YEARLY},
];

export const HalfYearOptions: ISelect<string>[] = [
  {label: "First Half (January to June)", value: "Q1"},
  {label: "Second Half (July to December)", value: "Q2"},
];

export const InventoryTransactionTypes: ISelect<string>[] = [
  {label: "Stock transfer", value: "Stock transfer"},
  {label: "Issue stock", value: "Issue stock"},
  {label: "Stock adjustment", value: "Stock adjustment"},
  {label: "Inventory reconciliation", value: "Inventory reconciliation"},
]

export const ItemSellingTypes: ISelect<string>[] = [
  {label: "Flat rate", value: "FLAT-RATE"},
  {label: "Percentage", value: "PERCENTAGE"},
];
export const ItemTypes: ISelect<string>[] = [
  {label: "Consumable", value: "CONSUMABLE"},
  {label: "Service", value: "SERVICE"},
  {label: "Stockable", value: "STOCKABLE"},
  {label: "Combo", value: "COMBO"},
];

export const LayoutViewTypes = [
  {label: "Compact", value: LayoutViewOptions.COMPACT},
  {label: "Extensive", value: LayoutViewOptions.EXTENSIVE},
];

export const MonthOptions: ISelect<string>[] = [
  {label: "January", value: "JANUARY"},
  {label: "February", value: "FEBRUARY"},
  {label: "March", value: "MARCH"},
  {label: "April", value: "APRIL"},
  {label: "May", value: "MAY"},
  {label: "June", value: "JUNE"},
  {label: "July", value: "JULY"},
  {label: "August", value: "AUGUST"},
  {label: "September", value: "SEPTEMBER"},
  {label: "October", value: "OCTOBER"},
  {label: "November", value: "NOVEMBER"},
  {label: "December", value: "DECEMBER"},
];

export const PaginationSize = [
  {label: "Items to display", value: "Items to display", isDisabled: true},
  {label: "10", value: PaginationOptions.TEN},
  {label: "20", value: PaginationOptions.TWENTY},
  {label: "30", value: PaginationOptions.THIRTY},
  {label: "50", value: PaginationOptions.FIFTY},
  {label: "100", value: PaginationOptions.HUNDRED},
];

export const PasswordRules = [
  {index: 1, label: "Number (123...)", pattern: NUMBER_REGEX},
  {index: 2, label: "8-12 characters long", pattern: LENGTH_REGEX},
  {index: 3, label: "Uppercase letter (ABC..)", pattern: UPPERCASE_REGEX},
  {index: 4, label: "Small letter (abc...)", pattern: LOWERCASE_REGEX},
  {index: 5, label: "Symbol (!@%...)", pattern: SPECIAL_CHARS_REGEX},
];

export const PaymentMethodType = [
  {label: "Cash", value: PaymentMethodEnums.CASH},
  {label: "Bank Transfer", value: PaymentMethodEnums.BANK_TRANSFER},
  {label: "Payment Terminal", value: PaymentMethodEnums.PAYMENT_TERMINAL},
];

export const PlanModulesData = [
  {
    label: "User and Subscription",
    name: "user_and_subscription",
    items: [
      {label: "Subscription", name: "sunscription"},
      {label: "User Profile", name: "user_profile"},
      {label: "Connected Devices", name: "connected_devices"},
    ]
  },
  {
    label: "Tenant Management",
    name: "tenanat_management",
    items: [
      {label: "Tenant Accounts", name: "tenant_accounts"},
      {label: "Tenant Roles and Permissions", name: "tenanat_roles_and_permissions"},
    ]
  },
  {
    label: "Sales and Payments",
    name: "sales_and_payments",
    items: [
      {label: "Orders", name: "orders"},
      {label: "Invoice", name: "invoice"},
      {label: "Receipts", name: "receipts"},
      {label: "Quotation", name: "quotation"},
      {label: "Sales Return", name: "sales_return"},
      {label: "Restaurant Orders", name: "resturant_orders"},
    ]
  },
  {
    label: "Banking and Payments",
    name: "banking_and_payments",
    items: [
      {label: "Payments", name: "payments"},
      {label: "Bank Balance", name: "bank_balance"},
      {label: "Expense Manager", name: "expense_manager"},
    ]
  },
  {
    label: "Procurement",
    name: "procurement",
    items: [
      {label: "Expense Manager", name: "expense_manager"},
      {label: "Purchase Orders", name: "purchase_orders"},
      {label: "Suppliers Management", name: "suppliers_management"},
    ]
  },
  {
    label: "Restaurant Manager",
    name: "resturant_manager",
    items: [
      {label: "Menu Managers", name: "menu_managers"},
      {label: "Restaurant Orders", name: "resturant_orders"},
      {label: "Tables and Region Manager", name: "tables_and_region_manager"},
    ]
  },
  {
    label: "Inventory Manager",
    name: "inventory_manager",
    items: [
      {label: "Reports", name: "reports"},
      {label: "Stock Transfer", name: "stock_transfer"},
      {label: "Inventory Counts", name: "inventory_counts"},
      {label: "Stock Adjustments", name: "stock_adjustments"},
    ]
  },
  {
    label: "Items and Price Management",
    name: "items_and_price_management",
    items: [
      {label: "Reports", name: "reports"},
      {label: "Categories", name: "categories"},
      {label: "Items Report", name: "items_reports"},
      {label: "Items", name: "items"},
      {label: "Pricing Tiers", name: "pricing_tiers"},
    ]
  },
  {
    label: "Customer Management",
    name: "customer_management",
    items: [
      {label: "Reports", name: "reports"},
      {label: "Categories", name: "categories"},
      {label: "Items Report", name: "items_reports"},
      {label: "Items", name: "items"},
      {label: "Pricing Tiers", name: "pricing_tiers"},
    ]
  }
];

export const PrintColumnSettings = {
  radio: [
    {
      label: "Items",
      name: ColumnSettingsParentKey.ITEM,
      items: [
        {label: "Items", name: "items"},
        {label: "Products", name: "Products"},
        {label: "Services", name: "Services"},
        {label: "Other", name: "other"},
      ]
    },
    {
      label: "Units",
      name: ColumnSettingsParentKey.UNIT,
      items: [
        {label: "Quantity", name: "quantities"},
        {label: "Hours", name: "hours"},
        {label: "Other", name: "other"},
      ]
    },
    {
      label: "Price",
      name: ColumnSettingsParentKey.PRICE,
      items: [
        {label: "Price", name: "price"},
        {label: "Rate", name: "rate"},
        {label: "Other", name: "other"},
      ]
    },
    {
      label: "Amount",
      name: ColumnSettingsParentKey.AMOUNT,
      items: [
        {label: "Amount", name: "amount"},
        {label: "Other", name: "other"},
      ]
    },
  ],
  checkBox: [
    {
      label: {start: "Choose which columns on your", end: "receipts to hide"},
      name: ColumnSettingsParentKey.HIDE_COLUMN,
      items: [
        {label: "Hide item", name: "hide_item"},
        {label: "Hide description", name: "hide_description"}
      ]
    },
    {
      label: {start: "Your", end: "must show at least one of the above"},
      name: ColumnSettingsParentKey.SHOW_COLUMN,
      items: [
        {label: "Hide quantity", name: "hide_quality"},
        {label: "Hide price", name: "hide_price"},
        {label: "Hide amount", name: "hide_amount"},
      ]
    },
  ]
}

export const QuarterOptions: ISelect<string>[] = [
  {label: "Q1 (January to March)", value: "Q1"},
  {label: "Q2 (April to June)", value: "Q2"},
  {label: "Q3 (July to September)", value: "Q3"},
  {label: "Q4 (October to December)", value: "Q4"},
];

export const RatingType = [
  {label: "Excellent", value: RatingsEnums.EXCELLENT},
  {label: "Very Good", value: RatingsEnums.VERY_GOOD},
  {label: "Good", value: RatingsEnums.GOOD},
  {label: "Poor", value: RatingsEnums.POOR},
  {label: "Very Poor", value: RatingsEnums.VERY_POOR},
];

export const ReconciliationType = [
	{label: "Opening Stock", value: ReconciliationTypeEnums.OPEN_STOCK},
	{label: "Reconciliation", value: ReconciliationTypeEnums.RECONCILIATION},
];

export const Roles = [
  {
    label: "User Management",
    name: "user_management",
    items: [
      {label: "User Profile", name: "user_profile"},
      {label: "User Role", name: "user_role"},
      {label: "Subscription", name: "subscription"},
      {label: "Connected Devices", name: "connected_devices"}
    ]
  },
  {
    label: "Sales and Payments",
    name: "sales_and_payments",
    items: [
      {label: "Invoice", name: "invoice"},
      {label: "Orders", name: "orders"},
      {label: "Quotation", name: "quotation"},
      {label: "Receipts", name: "receipts"},
      {label: "Sales Return", name: "sales_return"},
      {label: "Restaurant Orders", name: "resturant_orders"}
    ]
  },
  {
    label: "Banking and Payments",
    name: "banking_and_payments",
    items: [
      {label: "Bank Balance", name: "bank_balance"},
      {label: "Payments", name: "payments"},
      {label: "Expense Manager", name: "expense_manager"}
    ]
  },
  {
    label: "Procurement",
    name: "procurement",
    items: [
      {label: "Suppliers Management", name: "suppliers_management"},
      {label: "Purchase Orders", name: "purchase_orders"}
    ]
  },
  {
    label: "Inventory Manager",
    name: "inventory_manager",
    items: [
      {label: "Stock Transfer", name: "stock_transfer"},
      {label: "Inventory Counts", name: "inventory_counts"},
      {label: "Stock Adjustments", name: "stock_adjustments"},
      {label: "Reports", name: "reports"},
    ]
  },
  {
    label: "Items and Price Management",
    name: "items_and_price_management",
    items: [
      {label: "Categories", name: "categories"},
      {label: "Items", name: "items"},
      {label: "Items Report", name: "items_reports"},
      {label: "Pricing Tiers", name: "pricing_tiers"},
      {label: "Reports", name: "reports"},
    ]
  },
  {
    label: "Customer Management",
    name: "customer_management",
    items: [
      {label: "Customers", name: "customer"},
      {label: "Feedbacks", name: "feedback"},
      {label: "Loyalty", name: "loyalty"},
    ]
  },
  {
    label: "General Settings",
    name: "general_settings",
    items: [
      {label: "Business Details", name: "business_details"},
      {label: "Payment Methods", name: "payment_methods"},
      {label: "Locations", name: "locations"},
      {label: "Currency & Taxes", name: "currency_&_taxes"},
      {label: "Print Template & Taxes", name: "print_template"},
      {label: "Email Notifications", name: "email_notification"},
    ]
  }
];

export const SortFilter = [
  {label: "Latest", value: SortOptions.LATEST},
  {label: "Oldest", value: SortOptions.OLDEST},
];

export const StatFilter = [
  {label: "Week", value: StatFilterEnums.WEEK},
  {label: "Month", value: StatFilterEnums.MONTH},
  {label: "Year", value: StatFilterEnums.YEAR},
]

export const Status: { label: string, value: string | boolean }[] = [
  {label: "Activated", value: true},
  {label: "Deactivated", value: false},
];

export const Status2 = [
  {label: "Yes", value: true},
  {label: "No", value: false},
];

export const StatusFilter: ISelect<string>[] = [
  {label: "All", value: "ALL"},
  {label: "Activated", value: "true"},
  {label: "Deactivated", value: "false"},
];

export const StockAdjustmentType = [
  {label: "Re-count", value: StockAdjustmentTypeEnums.RE_COUNT},
  {label: "Loss", value: StockAdjustmentTypeEnums.LOSS},
  {label: "Damage", value: StockAdjustmentTypeEnums.DAMAGE},
  {label: "Return", value: StockAdjustmentTypeEnums.RETURN},
  {label: "Theft", value: StockAdjustmentTypeEnums.THEFT},
  {label: "Stock received", value: StockAdjustmentTypeEnums.STOCK_RECEIVED},
];

export const StockPartialStatus = [
  {label: "Draft", value: StockStatusTypeEnums.DRAFT},
  {label: "Completed", value: StockStatusTypeEnums.COMPLETED},
  {label: "Cancelled", value: StockStatusTypeEnums.CANCELLED},
];

export const StockStatus = [
  {label: "Draft", value: StockStatusTypeEnums.DRAFT},
  {label: "Submitted", value: StockStatusTypeEnums.SUBMITTED},
  {label: "Partial", value: StockStatusTypeEnums.PARTIAL},
  {label: "Completed", value: StockStatusTypeEnums.COMPLETED},
  {label: "Cancelled", value: StockStatusTypeEnums.CANCELLED},
];

export const TaxType = [
  {label: "Fixed", value: TaxTypeEnums.FIXED},
  {label: "Percentage", value: TaxTypeEnums.PERCENTAGE},
];

export const TemplateTypeOptions = [
  {label: "Basic", name: TemplateTypeEnums.BASIC},
  {label: "Modern", name: TemplateTypeEnums.MODERN},
  {label: "Hyper", name: TemplateTypeEnums.HYPER}
]

export const ToastObject = {
  error: {
    imgSource: ErrorSVG,
    borderClass: "border-danger-500-left",
    progressBarClass: "bg-danger-500",
  },
  success: {
    imgSource: SuccessSVG,
    borderClass: "border-success-600-left",
    progressBarClass: "bg-success-600",
  },
};


export const ValuationMethod: ISelect<string>[] = [
  {label: "FIFO (First In, First Out)", value: "FIFO"},
  {label: "LIFO (Last In, First Out)", value: "LIFO"},
  {label: "Moving Average", value: "MOVING-AVERAGE"},
];
