import { createApi } from "@reduxjs/toolkit/query/react";
import {backOfficeBaseQuery} from "./backoffice.slice";

export const tenantQuery = createApi({
  baseQuery: backOfficeBaseQuery,
  reducerPath: 'tenantQuery',
  tagTypes: ['active_plan', 'billing_history', 'connected_device', 'tenant', 'tenant_locations', 'tenant_member', 'plan_history', 'roles'],
  endpoints: (builder) => ({
    getBusinessInformation: builder.query({
      query: filter => `/tenancy-management/tenant/${filter}`,
      providesTags: ['tenant']
    }),
    updateBusinessInformation: builder.mutation({
      query: ({ id, payload }) => ({ url: `/tenancy-management/tenant/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['tenant']
    }),
    getBusinessLocation: builder.query({
      query: (filter) => ({ url: `/tenancy-management/tenant-location/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['tenant_locations']
    }),
    addBusinessLocation: builder.mutation({
      query: body => ({ url: `/tenancy-management/tenant-location/`, method: "POST", body }),
      invalidatesTags: ['tenant_locations']
    }),
    updateBusinessLocation: builder.mutation({
      query: ({ id, payload }) => ({ url: `/tenancy-management/tenant-location/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['tenant_locations']
    }),
    deleteBusinessLocation: builder.mutation({
      query: (id) => ({ url: `/tenancy-management/tenant-location/${id}/`, method: "DELETE" }),
      invalidatesTags: ['tenant_locations']
    }),
    toggleBusinessLocationStatus: builder.mutation({
      query: ({ id, suffix }) => ({ url: `/tenancy-management/tenant-location/${id}/${suffix}/`, method: "GET" }),
      invalidatesTags: ['tenant_locations']
    }),

    /** TEAM MEMBERS */
    getUserProfile: builder.query({
      query: filter => ({
        url: `/tenancy-management/tenant-member/${filter}`, headers: {admin: "admin" }
    }),
      providesTags: ['tenant_member'],
    }),
    addUserProfile: builder.mutation({
      query: ({ callbackURL, payload }) => ({
        url: `/tenancy-management/tenant-member/?callback_url=${callbackURL}`,
        method: "POST", body: payload, headers: {admin: "admin" }
      }),
      invalidatesTags: ['tenant_member']
    }),
    uploadUserPicture: builder.mutation<IApiResponse<IUser>, { id: string | undefined, payload: { profile_image: string}  }>({
      query: ({ id, payload }) => ({
        url: `/user-management/${id}/update_profile_picture_admin/`,
        method: "POST",
        body: payload,
        headers: {images: "['profile_image']"}
      }),
      invalidatesTags: ['tenant_member']
    }),
    updateUserProfile: builder.mutation({
      query: ({ id, payload }) => ({ url: `/tenancy-management/tenant-member/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['tenant_member']
    }),
    deleteUserProfile: builder.mutation<IApiResponse<IUser>, string>({
      query: (id) => ({ url: `/tenancy-management/tenant-member/${id}/`, method: "DELETE" }),
      invalidatesTags: ['tenant_member']
    }),
    toggleUserProfileStatus: builder.mutation({
      query: ({ id, suffix }) => ({ url: `/tenancy-management/tenant-member/${id}/${suffix}/`, method: "GET" }),
      invalidatesTags: ['tenant_member']
    }),

    /** TENANT ROLES */
    getRoles: builder.query({
      query: filter => ({ url: `/identity-management/roles/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['roles']
    }),
    addRole: builder.mutation({
      query: body => ({ url: `/identity-management/roles/`, method: "POST", body }),
      invalidatesTags: ['roles']
    }),
    updateRole: builder.mutation({
      query: ({ id, payload }) => ({ url: `/identity-management/roles/${id}/`, method: "PUT", body: payload }),
      invalidatesTags: ['roles']
    }),
    toggleRoleStatus: builder.mutation({
      query: ({ id, suffix }) => ({ url: `/identity-management/roles/${id}/${suffix}/`, method: "GET" }),
      invalidatesTags: ['roles']
    }),

    /** SUBSCRIPTIONS AND ADDONS */
    getActivePlan: builder.query({
      query: () => ({ url: `/subscriptions/plans/`, headers: {admin: "admin" } }),
      providesTags: ['active_plan']
    }),
    renewSubscription: builder.mutation({
      query: id => ({ url: `/subscriptions/plans/${id}/renew/`, method: "GET" }),
      invalidatesTags: ['active_plan']
    }),
    addAddonToPlan: builder.mutation({
      query: payload => ({ url: `/subscriptions/addons/`, body: payload, method: "POST" }),
      invalidatesTags: ['active_plan']
    }),
    getConnectedDevices: builder.query({
      query: (filter) => ({ url: `/tenancy-management/tenant-device/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['connected_device']
    }),
    toggleConnectedDeviceStatus: builder.mutation({
      query: ({ id, suffix }) => ({ url: `/tenancy-management/tenant-device/${id}/${suffix}/`, method: "GET" }),
      invalidatesTags: ['connected_device']
    }),
    getBillingHistory: builder.query({
      query: (filter) => ({ url: `/payments/webhooks/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['billing_history']
    }),
    getPlanHistory: builder.query({
      query: (filter) => ({ url: `/subscriptions/plans-history/${filter}`, headers: {admin: "admin" } }),
      providesTags: ['plan_history']
    }),
    upgradePlan: builder.mutation({
      query: payload => ({ url: `/subscriptions/plans/upgrade/`, method: "POST", body: payload }),
      invalidatesTags: ['active_plan', 'billing_history', 'tenant', 'plan_history']
    }),
    addAddonsToPlan: builder.mutation({
      query: payload => ({ url: `/subscriptions/addons/`, method: "POST", body: payload }),
      invalidatesTags: ['active_plan', 'billing_history', 'plan_history']
    }),
  })
});

export const {
  useGetBusinessInformationQuery,
  useUpdateBusinessInformationMutation,
  useToggleBusinessLocationStatusMutation,
  useGetBusinessLocationQuery,
  useAddBusinessLocationMutation,
  useDeleteBusinessLocationMutation,
  useUpdateBusinessLocationMutation,

  useGetUserProfileQuery,
  useUploadUserPictureMutation,
  useUpdateUserProfileMutation,
  useDeleteUserProfileMutation,
  useToggleUserProfileStatusMutation,

  useGetRolesQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useToggleRoleStatusMutation,

  useGetActivePlanQuery,
  useRenewSubscriptionMutation,
  useGetConnectedDevicesQuery,
  useToggleConnectedDeviceStatusMutation,
  useGetBillingHistoryQuery,
  useGetPlanHistoryQuery,
  useUpgradePlanMutation,
  useAddAddonsToPlanMutation,
} = tenantQuery;
