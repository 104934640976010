import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from "@reduxjs/toolkit/query";
import { createLogger } from 'redux-logger';

import authReducer from './slices/auth.slice';
import { salesQuery } from './slices/sales.slice';
import { tenantQuery } from './slices/tenant.slice';
import componentReducer from './slices/component.slice';
import { customerQuery } from './slices/customer.slice';
import { inventoryQuery } from './slices/inventory.slice';
import { backOfficeQuery } from "./slices/backoffice.slice";
import { configurationQuery } from './slices/configuration.slice';

const loggerMiddleware = () => {
  if (process.env.NODE_ENV === 'development') return [createLogger({ collapsed: true })];
  return [];
};

export const store = configureStore({
  reducer: {
    [salesQuery.reducerPath]: salesQuery.reducer,
    [tenantQuery.reducerPath]: tenantQuery.reducer,
    [customerQuery.reducerPath]: customerQuery.reducer,
    [inventoryQuery.reducerPath]: inventoryQuery.reducer,
    [backOfficeQuery.reducerPath]: backOfficeQuery.reducer,
    [configurationQuery.reducerPath]: configurationQuery.reducer,
    auth: authReducer,
    component: componentReducer,
  },
  middleware: initMiddleware => initMiddleware().concat(
    backOfficeQuery.middleware, configurationQuery.middleware, customerQuery.middleware,
    inventoryQuery.middleware, salesQuery.middleware, tenantQuery.middleware,
  ).concat(loggerMiddleware()),
  devTools: false
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch);