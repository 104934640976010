import {AxiosResponse} from 'axios';
import {instance as Axios} from './backoffice.service';

const APIService = {
  // AUTH SERvICE
  login(duration: string | number, domain: string, payload: { email: string, password: string }) {
    return Axios({
      method: 'POST',
      url: `/auth/signin/?duration=${duration}&tenant=Business&unique_url=${domain}`,
      data: payload,
    });
  },

  verifyAccount(payload: { type: string, value: string }) {
    return Axios({
      method: 'POST',
      url: `/tenancy-management/tenant/verify_detail_checker/`,
      data: payload,
    });
  },

  register(callbackURL: string, payload: any) {
    return Axios({
      method: 'POST',
      url: `/tenancy-management/tenant/onboard/?callback_url=${callbackURL}`,
      data: payload,
    });
  },

  resendConfirmationToken(callbackURL: string, payload: { email: string }) {
    return Axios({
      method: 'POST',
      url: `/tenancy-management/tenant/resend_confirmation_activate/?callback_url=${callbackURL}`,
      data: payload,
    });
  },

  onboard(token: string | null, payload: any) {
    return Axios({
      method: 'POST',
      url: `/tenancy-management/tenant/activate_tenant_account/?activate=${token}`,
      data: payload,
    });
  },

  forgotURL(payload: any) {
    return Axios({
      method: 'POST',
      url: `/tenancy-management/tenant/tenant_forget_url/`,
      data: payload,
    });
  },

  forgotPassword(callbackURL: string, uniqueURL: string, payload: any) {
    return Axios({
      method: 'POST',
      url: `/user-management/password_reset/?unique_url=${uniqueURL}&callback_url=${callbackURL}`,
      data: payload,
    });
  },

  resetPassword(token: string | null, payload: any) {
    return Axios({
      method: 'POST',
      url: `/user-management/password_reset_complete/?reset=${token}`,
      data: payload,
    });
  },

  logout() {
    return Axios({
      method: 'GET',
      url: `/auth/signout/`,
    });
  },

  // OTHER SERVICE
  addUserProfile(callbackURL: string, payload: any, header: null | object): Promise<AxiosResponse<IApiResponse<IUser>, any>> {
    let headers = {admin: "admin"};
    headers = header ? {...headers, ...header} : headers;

    return Axios({
      method: 'POST',
      url: `/tenancy-management/tenant-member/?callback_url=${callbackURL}`,
      data: payload,
      headers: headers
    });
  },

  applyCustomerFeedback(payload: Partial<ICustomerFeedbackProps>, callbackURL: string) {
    return Axios({
      method: 'POST',
      url: `/sales/transaction-feedback/?callback_url=${callbackURL}`,
      data: payload
    });
  },
  getTenants(filter: string): Promise<AxiosResponse<IApiResponse<any>, any>> {
    return Axios({
      method: 'GET',
      url: `/tenancy-management/tenant/${filter}`,
    });
  },
  getUnprotectedFeedbackData(type: string, uniqueURL: string, filter: any) {
    return Axios({
      method: 'GET',
      url: `/sales/transaction-feedback/customer_feedback_link_validate/?type=${type}&unique_url=${uniqueURL}${filter}`
    })
  },
  sendCustomerEmail(payload: IEmail) {
    return Axios({
      method: "POST",
      url: `/crm/customer/profile/send_customer_email/`,
      data: payload
    })
  },

  sendStockIssueEmail(id: string | undefined, payload: IStockEmail) {
    return Axios({
      method: "POST",
      url: `/inventory/stock-issue/${id}/send_stock_issue_invoice_email/`,
      data: payload
    })
  },
  sendStockTransferEmail(id: string | undefined, payload: IStockEmail) {
    return Axios({
      method: "POST",
      url: `/inventory/stock-transfer/20e50859-93ec-487b-ac65-6df5a1342776/send_stock_transfer_invoice_mail/`,
      data: payload
    })
  },
  sendStockAdjustmentEmail(id: string | undefined, payload: IStockEmail) {
    return Axios({
      method: "POST",
      url: `/inventory/stock-adjustment/${id}/send_stock_issue_adjustment_email/`,
      data: payload
    })
  },
  sendReconciliationEmail(id: string | undefined, payload: IStockEmail) {
    return Axios({
      method: "POST",
      url: `/inventory/stock-reconcilliation/${id}/end_stock_issue_reconcilliation_email/`,
      data: payload
    })
  },
};

export default APIService;
