import {NavLink} from "react-router-dom";
import ErrorSVG from "../../assets/images/svgs/error-404.svg";
import ErrorLayout from "../../components/layouts/ErrorLayout";

const Error404 = () => {
	return (
			<ErrorLayout image={ErrorSVG} title="404 - Page Not Found" 
										message="We have no clue what you’re looking for. Let’s help you navigate to a familiar page.">
				<NavLink className="btn btn-primary btn-sm" to="/">Phaneum Home</NavLink>
			</ErrorLayout>
	)
}

export default Error404;
