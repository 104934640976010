import {read, utils} from 'xlsx';
import {ColumnDef, createColumnHelper} from "@tanstack/react-table";
import {differenceInDays, endOfDay, format, formatDistance, isValid, startOfDay} from 'date-fns';

import {ColumnSettings} from './constants';
import {getBaseUrlEnv} from '../services/backoffice.service';
import {VALID_HEX_REGEX} from '../services/validator.service';
import {
  ColumnSettingsParentKey, Environment, InventoryReportNavEnums, LayoutViewOptions, NumberSign,
  SortOptions, SubscriptionDurationEnums, SubscriptionPaymentStatusEnums
} from '../../types/enums';
import {ProjectionCompactColumns} from "../../components/dataTables/atom/ProjectionCompactColumns";
import {StockLedgerCompactColumns} from "../../components/dataTables/atom/StockLedgerCompactColumns";
import {InventoryValuationColumns} from "../../components/dataTables/atom/InventoryValuationColumns";
import {ProjectionExtensiveColumns} from "../../components/dataTables/atom/ProjectionExtensiveColumns";
import {StockLedgerExtensiveColumns} from "../../components/dataTables/atom/StockLedgerExtensiveColumns";
import {StockTransferCompactColumns} from "../../components/dataTables/atom/StockTransferCompactColumns";
import {LowStockItemsCompactColumns} from "../../components/dataTables/atom/LowStockItemsCompactColumns";
import {LowStockItemsExtensiveColumns} from "../../components/dataTables/atom/LowStockItemsExtensiveColumns";
import {StockAdjustmentCompactColumns} from "../../components/dataTables/atom/StockAdjustmentCompactColumns";
import {StockTransferExtensiveColumns} from "../../components/dataTables/atom/StockTransferExtensiveColumns";
import {StockAdjustmentExtensiveColumns} from "../../components/dataTables/atom/StockAdjustmentExtensiveColumns";


export const setKey = (key: string) => {
  const env = getBaseUrlEnv() === Environment.PRODUCTION ? Environment.PRODUCTION : Environment.SANDBOX;
  return `CLIENT_${env}-${key}`;
}

/*** ARRANGED IN ALPHABETICAL ORDER ***/
export const badgeType = (num: number | string): string => {
  if (checkNumberSign(num) === NumberSign.POSITIVE) return "badge-success";
  else if (checkNumberSign(num) === NumberSign.NEGATIVE) return "badge-error";
  else return "badge-warning";
};

export const buildQueryString = (obj: Record<string, any>): string => {
  return Object.entries(obj)
    .filter(([_, value]) => value !== undefined && value !== null && value !== "")
    .map(([key, value]) => `&${key}=${value}`)
    .join("");
}

export const checkNumberSign = (num: number | string | undefined): string => {
  if (!num) return NumberSign.NEUTRAL;

  const sign = Math.sign(Number(num));
  if (sign === 1) return NumberSign.POSITIVE;
  if (sign === -1) return NumberSign.NEGATIVE;
  return NumberSign.NEUTRAL;
}

export const convertJSONtoCSV = async (payload: string | any[]) => {
  let data = "", header: string[] = [];

  for (let i = 0; i < payload.length; i++) {
    let result = flattenTextObject(payload[i]);
    let row = '';

    for (const index in result) {
      if (row !== '') row += ',';
      row += `"${result[index].toString()}"`;
    }

    if (i === 0) header = Object.keys(result);
    data += `${row}\r\n`;
  }
  let updatedHeader = Object.keys(header).map((key: any) => toTitleCase(header[key]).toUpperCase());
  return `${updatedHeader}\r\n${data}`;
};

export const convertNumberToShortForm = (num: number | string | undefined): string => {
  let updatedNum = Number(num);

  if (!num || typeof updatedNum !== 'number') return "0";

  else if (updatedNum >= 1_000_000_000_000) return (updatedNum / 1_000_000_000_000).toFixed(1).replace(/\.0$/, '') + 'T';
  else if (updatedNum >= 1_000_000_000) return (updatedNum / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
  else if (updatedNum >= 1_000_000) return (updatedNum / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  else if (updatedNum >= 1_000) return (updatedNum / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  else return updatedNum.toString();
}

/* START: CONVERT NUMBER TO WORDS*/
const units = [
  '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'
];
const teens = [
  'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
];
const tens = [
  '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
];
const thousands = [
  '', 'Thousand', 'Million', 'Billion', 'Trillion'
];

const convertHundreds = (num: number | string): string => {
  let result = '';
  let updatedNum = (typeof num === "string") ? Number(num) : num;

  if (updatedNum >= 100) {
    result += `${units[Math.floor(updatedNum / 100)]} hundred `;
    updatedNum %= 100;
  }
  if (updatedNum >= 10 && updatedNum <= 19) {
    result += `${teens[updatedNum - 10]} `;
  } else if (updatedNum >= 20) {
    result += `${tens[Math.floor(updatedNum / 10)]} `;
    updatedNum %= 10;
  }
  if (updatedNum > 0 && updatedNum < 10) result += `${units[updatedNum]} `;
  return result.trim();
};

export const convertNumberToWords = (num: number | string): string => {
  if (!num || isNaN(Number(num))) return "";

  else {
    let updatedNum = (typeof num === "string") ? Number(num) : num;

    if (updatedNum === 0) return 'zero';
    if (updatedNum < 0) return `negative ${convertNumberToWords(-num)}`;

    let result = '';
    let thousandCounter = 0;

    while (updatedNum > 0) {
      const chunk = updatedNum % 1000;
      if (chunk > 0) {
        result = `${convertHundreds(chunk)} ${thousands[thousandCounter]} ${result}`;
      }
      updatedNum = Math.floor(updatedNum / 1000);
      thousandCounter++;
    }
    return result.trim();
  }
};
/* END: CONVERT NUMBER TO WORDS*/

export const copyToClipboard = async (text: string) => {
  return await navigator.clipboard.writeText(text);
}

export const exportCSVData = (data: BlobPart, fileName: string, type: string) => {
  const blob = new Blob([data], {type});
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export const deepClone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const fileToBase64 = (event: any) => {
  let files = event;
  if (event && ((event.dataTransfer && event.dataTransfer.files) || (event.target && event.target.files))) {
    return new Promise((resolve, reject) => {
      if (event.dataTransfer) files = event.dataTransfer.files;
      else if (event.target) files = event.target.files;
      const reader = new FileReader();
      reader.onload = () => resolve(reader['result']);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(files[0]);
    });
  } else return null;
};

export const filterStatesByCountry = (payloadData: ICountry[], businessCountry: any) => {
  if (!businessCountry["country"]) return payloadData;
  return payloadData.filter(item => item["country"] === businessCountry["country"]);
};

const flattenBooleanJSON = (permissions: IDynamicDataType): { [key: string]: any } => {
  let returnData: { [key: string]: any } = {};

  for (let key in permissions) {
    if (!permissions.hasOwnProperty(key)) continue;

    if (!permissions[key]) {
      returnData[key] = false;
    } else if (typeof permissions[key] === 'object' && permissions[key] !== null) {
      let flatObject = flattenBooleanJSON(permissions[key]);
      for (let subKey in flatObject) {
        if (!flatObject.hasOwnProperty(subKey)) continue;
        if (flatObject[subKey] === 'true') returnData[`${key}.${subKey}`] = true;
        else if (flatObject[subKey] === 'false') returnData[`${key}.${subKey}`] = false;
        else returnData[`${key}.${subKey}`] = flatObject[subKey];
      }
    } else {
      if (permissions[key] === 'true') returnData[key] = true;
      else if (permissions[key] === 'false') returnData[key] = false;
      else returnData[key] = permissions[key];
    }
  }
  return returnData;
}

export const flattenTextObject = (obj: { [key: string]: any; }) => {
  let result: { [key: string]: string } = {};

  for (const key in obj) {

    if (!obj[key]) result[key] = "_";
    else if (typeof obj[key] === "object") {
      let subFlatObject = flattenTextObject(obj[key]);

      if (Array.isArray(obj[key])) {
        for (const arrElement of obj[key]) {
          let subFlatObject = flattenTextObject(arrElement);

          for (const subKey in arrElement) {
            if (!subFlatObject[subKey]) result[key + "_" + subKey] = "_"
            else result[key + "_" + subKey] = subFlatObject[subKey];
          }
        }
      } else {
        for (const subKey in subFlatObject) {
          if (!subFlatObject[subKey]) result[key + "_" + subKey] = "_"
          else result[key + "_" + subKey] = subFlatObject[subKey];
        }
      }
    } else result[key] = obj[key];
  }
  return result;
};

/* START: HEX TO RGBA*/
const convertHexUnitTo256 = (hexStr: string): number => parseInt(hexStr.repeat(2 / hexStr.length), 16)

const getAlphaFloat = (a: number, alpha: number | undefined) => {
  if (a) return a / 255;
  if ((typeof alpha != "number") || alpha < 0 || alpha > 1) return 1;
  return alpha
}

const getChunksFromString = (st: string, chunkSize: number): RegExpMatchArray | null => st.match(new RegExp(`.{${chunkSize}}`, "g"));

export const formatHexToRgbA = (hex: string, alpha: number = 1) => {
  if (!hex || !hex.match(VALID_HEX_REGEX)) return undefined;

  const chunkSize = Math.floor((hex.length - 1) / 3)
  const hexArr = getChunksFromString(hex.slice(1), chunkSize)
  const [r, g, b, a] = hexArr ? hexArr.map(data => convertHexUnitTo256(data)) : [0, 0, 0, 0];
  return {r, g, b, a: getAlphaFloat(a, alpha)};
};
/* END: HEX TO RGBA*/

export const formatRGBAToHex = (rgba: string, forceRemoveAlpha: boolean = false) => {
  return "#" + rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
    .split(',') // splits them at ","
    .filter((string, index) => !forceRemoveAlpha || index !== 3)
    .map(string => parseFloat(string)) // Converts them to numbers
    .map((number, index) => index === 3 ? Math.round(number * 255) : number) // Converts alpha to 255 number
    .map(number => number.toString(16)) // Converts numbers to hex
    .map(string => string.length === 1 ? "0" + string : string) // Adds 0 when length of one number is 1
    .join("") // Puts the array too together to a string
}

export const formatNumber = (value: number | string | undefined, isDecimal?: boolean) => {
  if (!value) return isDecimal ? 0.00 : 0;

  let numFormat = Intl.NumberFormat('en-US', {
    style: "decimal",
    minimumFractionDigits: isDecimal ? 2 : 0,
    maximumFractionDigits: 2
  });
  return numFormat.format(value as number);
};

export const formatSelectData = (payload: IDynamicDataType[], labelKey: string, valueKey: string): ISelect<string>[] => {
  return payload.map(data => ({
    label: data[labelKey],
    value: data[valueKey]
  }))
}

export const formatSubscriptionData = (payload: IPlan) => {
  let formattedData,
    planCost = (payload['monthly_pricing'] && payload['subscription_duration_type'] === SubscriptionDurationEnums.MONTHLY) ? `${isNaN(Number(payload['monthly_pricing'])) ? "0.00" : payload['monthly_pricing']}`
      : (payload['yearly_pricing'] && payload['subscription_duration_type'] === SubscriptionDurationEnums.YEARLY) ? `${isNaN(Number(payload['yearly_pricing'])) ? "0.00" : payload['yearly_pricing']}` : "0.00",
    totalPricing = parseFloat(planCost as string)

  formattedData = [
    [{text: 'Title', style: 'tableHeader'}, {text: 'Type', style: 'tableHeader'}, {
      text: 'Pricing',
      style: 'tableHeader'
    }],
    [
      {
        text: `${payload['plan'] ? payload['plan'] : "-"}${payload['mode'] ? ` - ${payload['mode']}` : ""}`,
        style: 'tableContent'
      },
      {text: 'Subscription', style: 'tableContent'},
      {
        text: `${(payload && payload['currency_details'] && payload['currency_details']['symbol']) ? payload['currency_details']['symbol'] : ""}${(payload['monthly_pricing'] && payload['subscription_duration_type'] === SubscriptionDurationEnums.MONTHLY) ? `${formatNumber(planCost, true)}/m` : (payload['yearly_pricing'] && payload['subscription_duration_type'] === SubscriptionDurationEnums.YEARLY) ? `${formatNumber(planCost, true)}/y` : "0.00"}`,
        style: 'tableContent'
      },]
  ];

  if (payload['addons_details'] && payload['addons_details'].length > 0) {
    for (let addon of payload['addons_details']) {
      let content = [{text: '', style: 'tableContent'}, {text: 'Addon', style: 'tableContent'}, {
        text: '',
        style: 'tableContent'
      }];

      content[0]["text"] = (addon && addon["name"]) ? toTitleCase(addon["name"]) : "-";
      let addonAmount = (addon['monthly_pricing'] && addon['addon_duration_type'] === SubscriptionDurationEnums.MONTHLY) ? `${isNaN(Number(payload['monthly_pricing'])) ? "0.00" : addon['monthly_pricing']}`
        : (addon['yearly_pricing'] && addon['addon_duration_type'] === SubscriptionDurationEnums.YEARLY) ? `${isNaN(Number(payload['yearly_pricing'])) ? "0.00" : addon['yearly_pricing']}` : "0.00";

      content[2]["text"] = `${(payload && payload['currency_details'] && payload['currency_details']['symbol']) ? payload['currency_details']['symbol'] : ""}${addonAmount}${addon['addon_duration_type'] === SubscriptionDurationEnums.MONTHLY ? "/m" : "/y"}`;
      totalPricing = totalPricing + parseFloat(addonAmount);
      formattedData = [...formattedData, content]
    }
  }

  let footer = [
    {
      colSpan: 2, rowSpan: 1, text: ['Status: ', {
        text: `${payload["subscription_payment_status"] === SubscriptionPaymentStatusEnums.PENDING ? 'Pending Payment' : 'Paid Successful'}`,
        color: `${payload["subscription_payment_status"] === SubscriptionPaymentStatusEnums.PENDING ? '#F3A218' : '#0F973D'}`
      }], style: 'tableContent'
    },
    "",
    {
      text: ["Total: ", {
        text: `${(payload && payload['currency_details'] && payload['currency_details']['symbol']) ? payload['currency_details']['symbol'] : ""}${formatNumber(totalPricing)}`,
        color: "#000000",
        bold: true
      }], style: 'tableContent'
    }
  ]

  return [...formattedData, footer];
};

export const generateInventoryReportColumns = <T, >(
  layoutView: string,
  _columnHelper: ReturnType<typeof createColumnHelper<T>>,
  type: InventoryReportNavEnums
): ColumnDef<T, any>[] => {
  switch (type) {
    case InventoryReportNavEnums.STOCK_ADJUSTMENTS:
      if (layoutView === LayoutViewOptions.COMPACT) {
        return StockAdjustmentCompactColumns as ColumnDef<T, any>[];
      } else {
        return StockAdjustmentExtensiveColumns as ColumnDef<T, any>[];
      }

    case InventoryReportNavEnums.STOCK_TRANSFER:
      if (layoutView === LayoutViewOptions.COMPACT) {
        return StockTransferCompactColumns as ColumnDef<T, any>[];
      } else {
        return StockTransferExtensiveColumns as ColumnDef<T, any>[];
      }

    case InventoryReportNavEnums.STOCK_LEDGER:
      if (layoutView === LayoutViewOptions.COMPACT) {
        return StockLedgerCompactColumns as ColumnDef<T, any>[];
      } else {
        return [...StockLedgerCompactColumns, ...StockLedgerExtensiveColumns] as ColumnDef<T, any>[];
      }

    case InventoryReportNavEnums.LOW_ITEMS:
      if (layoutView === LayoutViewOptions.COMPACT) {
        return LowStockItemsCompactColumns as ColumnDef<T, any>[];
      } else {
        return LowStockItemsExtensiveColumns as ColumnDef<T, any>[];
      }

    case InventoryReportNavEnums.PROJECTIONS:
      if (layoutView === LayoutViewOptions.COMPACT) {
        return ProjectionCompactColumns as ColumnDef<T, any>[];
      } else {
        return ProjectionExtensiveColumns as ColumnDef<T, any>[];
      }

    case InventoryReportNavEnums.VALUATION:
      return InventoryValuationColumns as ColumnDef<T, any>[];

    default:
      return [];
  }
};

export const getDateDifference = (date: Date | null) => {
  if (!date || !isValid(parseDate(date))) return format(new Date(), "d LLL, yyyy")
  else if (differenceInDays(new Date(), parseDate(date)) < 7) return `${formatDistance(parseDate(date), new Date(), {includeSeconds: true})} ago`
  else return format(parseDate(date), "d LLL, yyyy")
};

export const getLuminance = (hexColor: string): number => {
  if (!hexColor) return 0;

  // Convert hex to RGB
  const hex = hexColor.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Apply the luminance formula
  const a = [r, g, b].map((v) =>
    v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  );
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
};

export const getMonthName = (value: number) => {
  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return month[value];
};

export const getObjectFromArray = (payload: any) => {
  if (!payload) return;
  return Array.isArray(payload) ? payload[0] : (payload && Object.entries(payload).length > 0) ? payload : null;
};

export const getWeek = (difference: number) => {
  let date = new Date();
  date.setDate(date.getDate() - date.getDay() + difference);
  return date;
};

export const getWeekRange = (startValue: number) => {
  const startDate = startOfDay(getWeek(startValue)).toISOString();
  const endDate = endOfDay(getWeek(startValue + 6)).toISOString();

  return {startDate, endDate}
};

export const getYear = () => {
  let date = new Date();
  return date.getFullYear();
};

export const isAllSelected = (state: Partial<Permissions>): boolean => {
  return Object.values(flattenBooleanJSON(state)).every(Boolean);
}

export const isAllTransactionsSelected = (references: string | any[], transactions: ITransaction[]) => {
  return transactions.every((transaction: ITransaction) => references.includes(transaction.transaction_id));
}

export const parseDate = (dateString?: Date | null | string) => {
  if (dateString) return new Date(dateString);
  else return new Date();
}

export const processSheet = (file: File) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (event: any) => {
      const wb = read(event.target.result);
      const sheets = wb.SheetNames;
      if (sheets.length) resolve(utils.sheet_to_json(wb.Sheets[sheets[0]]))
    }
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

export const queryErrorHandler = (type: string, response?: { data?: any; error?: any; }) => {
  if (!response) return `You cannot ${type.toLowerCase()} at this time, Please try again later.`;
  if (response && response.error && response.error.data && response.error.data.message) return response.error.data.message;
  else if (response && response.data && response.data.message) return response.data.message;
  else return `You cannot ${type.toLowerCase()} at this time, Please try again later.`;
};

export const queryImportErrorHandler = (type: string, response?: { data?: any; error?: any; }) => {
  if (!response || !response.error || !response.error.data) return `You cannot ${type.toLowerCase()} at this time, Please try again later.`;

  const errorData = response && response.error && response.error.data;
  return (errorData.data["FAILED_IMPORT_DETAILS"] && errorData.data["FAILED_IMPORT_DETAILS"][0] && errorData.data["FAILED_IMPORT_DETAILS"][0]["error"])
    ? errorData.data["FAILED_IMPORT_DETAILS"][0]["error"] : (errorData && errorData.message) ? errorData.message : `You cannot ${type.toLowerCase()} at this time, Please try again later.`
};

export const sortByDate = (sortType: SortOptions, key: string, payloadData: INote[]) => {
  if (sortType === SortOptions.LATEST) return payloadData.sort((a, b) => parseDate(b[key]).getTime() - parseDate(a[key]).getTime());
  else return payloadData.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
}

export const togglePermissionSelection = (data: IDynamicDataType, value: boolean) => {
  const clonedData = deepClone(data); // Deep clone the object to avoid modifying the original

  const traverse = (obj: IDynamicDataType): IDynamicDataType => {
    for (const key of Object.keys(obj)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) obj[key] = traverse(obj[key]); // Recursively handle nested objects
      else if (typeof obj[key] === 'boolean') obj[key] = value; // Update the boolean value
    }
    return obj;
  };

  return traverse(clonedData);
}

export const toTitleCase = (str: string | undefined) => {
  if (!str || str.length === 0) return "-";
  str = (/[_&\\/#, +()$~%.'":*?<>{}-]/g).test(str) ? str.replaceAll(/[_&\\/#, +()$~%.'":*?<>{}-]/g, ' ') : str;
  return str.trim().split(' ').map(word => (
    word[0] ? word[0].toUpperCase() + word.substring(1).toLowerCase() : word + word.substring(1).toLowerCase()
  )).join(' ')
}

export const updatePrintTemplateColumn = (type: string, obj: any, value: boolean | string, parentKey: ColumnSettingsParentKey, key: string) => {
  if (parentKey === ColumnSettingsParentKey.HIDE_COLUMN || parentKey === ColumnSettingsParentKey.SHOW_COLUMN) {
    return {
      ...obj[type], [parentKey]: {...obj[type][parentKey], [key]: value}
    };
  } else {
    return {
      ...obj[type],
      [parentKey]: {
        ...obj[type][parentKey], ...Object.keys(ColumnSettings[parentKey]).reduce((acc: any, currKey: string) => {
          if (key === "value") {
            if (currKey === "other") acc[currKey] = true;
            else if (currKey === "value") acc[currKey] = value;
            else acc[currKey] = false;
          } else {
            if (currKey === key) acc[currKey] = true;
            else if (currKey === "value") acc[currKey] = "";
            else acc[currKey] = false;
          }
          return acc;
        }, {})
      }
    }
  }
}

export const yearPicker = (startRange: number): ISelect<string | number>[] => {
  const date = new Date();
  let array: ISelect<string | number>[] = [];
  while (startRange <= date.getFullYear()) {
    const newObject = {label: startRange.toString(), value: startRange.toString()}
    array.unshift(newObject);
    startRange++
  }
  return array;
};
